import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import PhotoCamera from '@material-ui/icons/PhotoCamera';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: 'none',
    width: '30px',
    height: '30px'
  },
}));

export default function Imagem({handleImagem, id}) {

  const classes = useStyles();
  const [imagem, setImagem] = useState('');
  const [cor, setCor] = useState('#565656')

  const _handleImage = (imagename) => {
    setImagem(imagename);
    setCor('#fff');
    handleImagem(id, imagename);
  }

  return (
    <div className={classes.root}>
      <input accept="image/*" className={classes.input} id={`icon-button-file${id}`} type="file" onChange={e => { _handleImage(e.target.files[0]); } } />
      <label htmlFor={`icon-button-file${id}`}>
        <IconButton color="primary" aria-label="upload picture" component="span">
          <PhotoCamera
            style={ {color: cor} }
          />
        </IconButton>
      </label>
    </div>
  );
}
