import React, { useState, useEffect } from "react";
import { AiFillPlusCircle } from "react-icons/ai";

import Imagem from '../components/Imagem';

export default function Pergunta({key, label, handleChange, handleObservacao, id, handleImagem}) {

    const [visible, setVisible] = useState(false);
    const [isSim, setIsSim] = useState(null);
    const [obs, setObs] = useState("");
    const [cor, setCor] =useState('#565656');

    useEffect(() => {
        handleChange(id, isSim);
    }, [isSim]);

    const okImagem = () => {
      setCor('#fff')
    }

    return(
        <div id={`pergunta-${key}`} className="Perguntas1">
          <p className="pergunta1"> {label} </p>

          <div className="Butoes">
            <button type="submit" className={`buttonsimm ${isSim && isSim !== null ? 'active' : ''}`}  onClick={ () => { setIsSim(true) } }>APROVADO</button>
            <button type="submit" className={`buttonnaoo ${!isSim && isSim !== null ? 'active' : ''}`} onClick={ () => { setIsSim(false) } }>REPROVADO</button>
            <div className="icon">
              <Imagem
                id = {id}
                handleImagem={handleImagem}
              />
            </div>

            <div onClick={() => setVisible(true)} className="icon">
              <AiFillPlusCircle   className="svgi1" style={{color: '#787878'}} />
                <button className="icon-label1">Adicionar observação</button>
            </div>


            <div className="textare">
              {visible?  <textarea onChange={event => {handleObservacao(id, event.target.value)}} className="textare0"  placeholder="Adicionar Observação" /> :null}
            </div>
          </div>

          <div className="textareaa">
            <div className="textareaa">
              {visible?  <textarea onChange={event => {handleObservacao(id, event.target.value)}} className="textarea0"  placeholder="Adicionar Observação" /> :null}
            </div>
          </div>
        </div>
    );
}
