import React from 'react';
import { config } from "../../http/checklist";

import './TestAlert.css';

export default function TestAlert() {
  return(
    <div className="test-alert">
      <div className="test-alert--wrapper">
        <div className="test-alert--title">Ambiente de Teste</div>
        <div className="test-alert--link">requests enviadas para: { config.baseURL }</div>
      </div>
    </div>
  );
}
