import axios from "axios";

let config = {
    baseURL: "https://teste.api.haut.id",
    // timeout: 10000
};

export default axios.create(config);

export { config };
