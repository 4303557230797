import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2'

import Checklist from "./http/checklist";
import HautDigital from "./http/hautDigital";

import Pergunta from "./components/pergunta";
import Imagem from './components/Imagem';
import Assinatura from './components/Assinatura/index';
import canvas from './components/Assinatura/index';

import Background from './Assets/background.jpg';
import Background2 from './Assets/haut-back.png';
import Background3 from './Assets/haut_fundo.png'

import './styles.css';
import checklist from './http/checklist';

// AVISO DE TESTE
import TestAlert from "./components/TestAlert";

function App() {

  const [isSubmit, setIsSubmit] = useState(false);

  const [clientes, setClientes] = useState([]);
  const [empreendimentos, setEmpreendimentos] = useState([]);
  const [empreendimentoActive, setEmpreendimentoActive] = useState(0);
  const [unidades, setUnidades] = useState([]);
  const [perguntas, setPerguntas] = useState([]);
  const [assinatura, setAssinatura] = useState("");

  const [id_cliente, setIdCliente] = useState(0);
  const [empreendimento, setEmpreendimento] = useState("");
  const [unidade, setUnidade]= useState("");

  useEffect(() => {
    Checklist.get("/perguntas/isActive/1")
    .then(res => {
      setPerguntas(res.data);
      Atualizarresposta();
    });

    Checklist.get("/clientes")
    .then(res => {
      setClientes( res.data );
    });
  }, []);

  useEffect(() => {
    Checklist.get(`/unidades/empreendimentos/${empreendimentoActive}/id_cliente/${id_cliente}`)
    .then(res => {
      setUnidades( res.data );
    });
  }, [empreendimentoActive]);

  const changeEmpreendimentoActive = newValue => {
    setEmpreendimentoActive(newValue);
    handleEmpreendimento(newValue);
  }

  const Atualizarresposta = () => {
    setPerguntas(
      perguntas.map(pergunta => {
        return {...pergunta, resposta: true}
      })
    );
  }

  const handleNome = newValue => {
    setIdCliente(newValue);

    Checklist.get(`/clientes/${newValue}/empreendimentos`)
    .then(res => {
      setEmpreendimentos( res.data );
    });
  }

  const handleUnidade = newValue => {
    const U = unidades.find(unidade => unidade.id == newValue);
    if  (U !== undefined) {
      setUnidade(U);
    }
  }

  const handleEmpreendimento = newValue => {
    const E = empreendimentos.find(empreendimento => empreendimento.id ==newValue);
    if ( E !== undefined) {
      setEmpreendimento(E.nome);
    }
  }

  const updatePerguntas = (key, newValue) => {
    setPerguntas(
      perguntas.map(pergunta => {
        if (pergunta.id == key) {
          return {...pergunta, resposta: newValue}
        }

        return pergunta;
      })
    );
  }

  const Adicionarobs = (key, newValue) => {
    setPerguntas(
      perguntas.map(pergunta => {
        if (pergunta.id == key) {
          return {...pergunta, observacao: newValue}
        }
        return pergunta;
      })
    );
  }

  const Adicionarimagem = (key, newValue) => {
    if (newValue === "") return;
    setPerguntas (
      perguntas.map(pergunta => {
        if (pergunta.id == key) {
          return{...pergunta, imagem: newValue}
        }
        return pergunta;
      })
    );
  }

  const enviar = () => {
    setIsSubmit(true);
    const data = {
      id_cliente,
      id_empreendimento: empreendimentoActive,
      id_unidade: unidade.id,
      nomeEmpreendimento: empreendimento,
      numeroUnidade: unidade.numero
    };

    Checklist.post("/checklist", data).then (response => {
      const id_checklist = response.data.id;

      const formData = new FormData();
      formData.append("assinatura", assinatura);
      Checklist.post(`/checklist/${id_checklist}/assinatura`, formData, {
        headers: {
          "Content-Type": `multipart/form-data; boundary=${formData._boundary}`
        }
      })
      .catch(err => {
        console.log("assinatura catch");
        console.log(err)
      });

      perguntas.map(pergunta => {
        if (pergunta.imagem === undefined) {
          const data = {
            valor: pergunta.resposta ? 1 : 0,
            observacao: pergunta.observacao,
            id_checklist: id_checklist,
            id_pergunta: pergunta.id
          }

          Checklist.post("/respostas/", data).catch(err => {
            console.log(err);
          });
        } else {
          let valor = pergunta.resposta ? 1 : 0;
          const formData = new FormData();
          formData.append("valor", valor);
          formData.append("observacao", pergunta.observacao);
          formData.append("id_checklist", id_checklist);
          formData.append("id_pergunta", pergunta.id);
          formData.append("imagem", pergunta.imagem);

          Checklist.post("/respostas/imagem" , formData, {
            headers: {
              "Content-Type": `multipart/form-data; boundary=${formData._boundary}`
            }
          }).catch(err => {
            console.log(err);
          });
        }
      });

      Swal.fire(
        "Checklist Enviado",
        "Suas respostas foram salvas, obrigado por responder",
        "success"
      );

    }).catch(e => {
      console.log("checklist 2 catch");
      console.log(e);
      Swal.fire(
        "Erro",
        "Infelizmente não foi possível salvar suas respostas, por favor tente novamente",
        "error"
      );

      setIsSubmit(false);
    })
  }


  const handleAssinatura = assinatura => {
    setAssinatura(assinatura)
  }

  return (
    <div style={{position: 'relative', zIndex: '1', overflow: 'hidden'}} className="Container">
      <TestAlert />
      <div className="Planta">
        <img alt="" src={Background} className="planta"/>
      </div>

      <div className="Background3">
        <img alt=""  src={Background3} className="background3" />
      </div>

      <div className="Titulo">
        <h1>TERMO DE VISTORIA</h1>
      </div>

      <div className="selects" style={{position: 'relative', zIndex: '1', display: 'flex'}} className="Selectt">
        <select onChange={event => handleNome(event.target.value)} className="select-avaliador select-geral" name="Nome do avaliador">
            <option value="nomedoavaliador">Nome do avaliador</option>
            { clientes.map(cliente => <option key={cliente.id} value={ cliente.id }>{ cliente.nome }</option>) }
          </select>
          <select
            className="select-empreendimento select-geral"
            name="Empreendimento"
            onChange={event => { changeEmpreendimentoActive(event.target.value) }}
          >
            <option value="empreendimento">Empreendimento</option>
            { empreendimentos.map(empreendimento => <option key={empreendimento.id} value={ empreendimento.id }>{ empreendimento.nome }</option>) }
          </select>
          <select onChange={event => handleUnidade(event.target.value)} className="select-unidade select-geral" name="Unidade">
            Unidade
            <option value="unidade">Unidade</option>
            { unidades.map(unidade => <option key={unidade.id} value={ unidade.id }>{ unidade.numero }</option>) }
          </select>
      </div>

      <div  className="Questions">
        <img alt="" style={{marginTop: '350px'}} src={Background2} className="background2" />

        { perguntas.map(pergunta => <Pergunta key={pergunta.id} id={pergunta.id} label={pergunta.label} handleChange={updatePerguntas} handleObservacao={Adicionarobs} handleImagem={Adicionarimagem}/>) }
        <div style={{marginTop: '10em'}}></div>

        <Assinatura
          handleAssinatura={handleAssinatura}
        />

        <div style={{textAlign: 'center', position: 'relative', zIndex: '1'}} className="Wrapper">
          <button className="buttonsimmm" onClick={enviar} disabled={isSubmit}>{isSubmit ? "Enviado" : "Enviar"}</button>
        </div>

        <div style={{marginTop: '2em'}}></div>
        <img alt="" style={{marginTop: '-100em'}}  src={Background3} className="background4" />
      </div>
    </div>
  );
}

export default App;
