import React, { useRef } from "react";
import "./Assinatura.css";
import CanvasDraw from "react-canvas-draw";

export default function Assinatura({ handleAssinatura }) {
    const canvasDrawn = useRef(null);

    const update = (state) => {
        const canvas = state.canvas.drawing;
        canvas.toBlob(function(blob) {
            handleAssinatura(blob);
        });
    }

    const limpar = () => {
        canvasDrawn.current.clear()
    }
    return(
        <div className="Container" >
            <div className="canvas__wrapper">

                <CanvasDraw 
                brushColor= '#000'
                backgroundColor= '#fff'
                gridColor= 'transparent'
                brushRadius= '2'
                catenaryColor= '#000'
                className="canvasss"
                canvasWidth="500px"
                canvasHeight="150px"
                onChange={update}
                ref={canvasDrawn}
                />
            </div>

            <div className="Clear">
                <button
                style={{marginLeft: 'auto', marginRight: 'auto', alignItems: 'center', marginTop:'100px'}}
                className="clear"
                onClick={limpar}
                >
                Clear
                </button>
            </div>
        </div>
    );
}